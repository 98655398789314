import { DOCUMENT } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  getLanguageCode,
  getCountryCode,
  SentryLocaleMetadata,
} from '@app/shared/utils/url.utils';
import { environment } from '@environments/environment';
import { UserTiming } from '@app/shared/utils/performance.utils';
import { configureScope, Scope } from '@sentry/browser';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import {
  DEFAULT_AVAILABLE_COUNTRIES,
  DEFAULT_AVAILABLE_LANGUAGES,
} from '@app/akita/api/products/models/products.state';

const PERFORMANCE_MARK_NAME = 'Inject Language Guard';
const PERFORMANCE_MARK_START = `${PERFORMANCE_MARK_NAME} - Start`;
const PERFORMANCE_MARK_END = `${PERFORMANCE_MARK_NAME} - End`;

@Injectable({
  providedIn: 'root',
})
export class LanguageGuard implements CanActivate {
  private readonly isBrowser: boolean;
  private requestURL: string;
  private readonly document: Document | null;
  private readonly clientRequest: any | null;

  constructor(
    private readonly injector: Injector,
    private readonly router: Router,
    private readonly akitaRouterQuery: AkitaRouterQuery
  ) {
    this.requestURL = '';
    this.isBrowser = this.akitaRouterQuery.isBrowser;

    if (!this.isBrowser) {
      try {
        /*  this.clientRequest = this.injector.get(REQUEST); */
      } catch (error) {
        this.clientRequest = null;
      }
    }

    try {
      this.document = this.injector.get(DOCUMENT);
    } catch (error) {
      this.document = null;
    }
  }

  private refreshSentryLocaleMetadata(metadata: SentryLocaleMetadata): void {
    configureScope((scope: Scope) => {
      scope.setExtra('i18n:requested', metadata.requestedCode);
      scope.setExtra('i18n:subDomain', metadata.hasSubDomain || '-');
      scope.setExtra('i18n:langParam', metadata.hasLangParam || '-');
      scope.setExtra('i18n:browser', metadata.hasBrowser);
      scope.setExtra('i18n:dateLocale', metadata.hasDateLocale);
      scope.setExtra('i18n:final', metadata.chosenLanguage);
    });
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): boolean | UrlTree {
    UserTiming.createMark(PERFORMANCE_MARK_START);

    let route = next;
    while (route.firstChild) {
      route = route.firstChild;
    }

    this.requestURL = '';
    if (this.clientRequest) {
      this.requestURL = `${this.clientRequest.get('host')}${
        this.clientRequest.originalUrl
      }`;
    } else if (this.document) {
      this.requestURL = `${this.document.location.origin || ''}${routerState.url}`;
    }

    // if the route has no country/language data , set the default country/language
    if (!route.data.country.length && !route.data.locale.length) {
      // process the language code from the action and decide which language should be set
      const isoCode = getLanguageCode(
        'en',
        this.requestURL,
        this.akitaRouterQuery.isBrowser,
        this.refreshSentryLocaleMetadata.bind(this)
      );
      // process the country code from the action and decide which country should be set
      let country = getCountryCode(
        'US',
        this.requestURL,
        this.refreshSentryLocaleMetadata.bind(this)
      ).country;

      let language;
      const tokens = isoCode.toLowerCase().split('-');
      if (tokens.length > 1) {
        country = (tokens[1] || '').toLowerCase();
        language = (
          (environment.languages as any)[tokens[1] || ''] ||
          tokens[0] ||
          'en'
        ).toLowerCase();
      } else {
        language = (tokens[0] || 'en').toLowerCase();
      }

      // check if the country and language are supported
      country = DEFAULT_AVAILABLE_COUNTRIES.includes(country?.toUpperCase() || '')
        ? country?.toLowerCase() || ''
        : 'sa';
      language = DEFAULT_AVAILABLE_LANGUAGES.includes(language?.toLowerCase() || '')
        ? language
        : 'en';

      const fragments = routerState.url.split('/').filter(Boolean);
      return this.router.createUrlTree([`${language}-${country}`, ...fragments]);
    }

    UserTiming.createMark(PERFORMANCE_MARK_END);
    UserTiming.measure(
      PERFORMANCE_MARK_NAME,
      PERFORMANCE_MARK_START,
      PERFORMANCE_MARK_END
    );
    return true;
  }

  // old code
  /*   public canActivate2(
    next: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): boolean | UrlTree {
    UserTiming.createMark(PERFORMANCE_MARK_START);
    let country, language;

    this.requestURL = '';
    if (this.clientRequest) {
      this.requestURL = `${this.clientRequest.get('host')}${
        this.clientRequest.originalUrl
      }`;
    } else if (this.document) {
      this.requestURL = `${this.document.location.origin || ''}${routerState.url}`;
    }

    // process the language code from the action and decide which language should be set
    const isoCode = getLanguageCode(
      'en',
      this.requestURL,
      this.akitaRouterQuery.isBrowser,
      this.refreshSentryLocaleMetadata.bind(this)
    );
    country = getCountryCode(
      'US',
      this.requestURL,
      this.refreshSentryLocaleMetadata.bind(this)
    ).country;

    const tokens = isoCode.toLowerCase().split('-');
    if (tokens.length > 1) {
      country = (tokens[1] || '').toLowerCase();
      language = (
        (environment.languages as any)[tokens[1] || ''] ||
        tokens[0] ||
        'en'
      ).toLowerCase();
    } else {
      language = (tokens[0] || 'en').toLowerCase();
    }
    const isRtl = isLanguageRtl(language);

    let route = next;
    while (route.firstChild) {
      route = route.firstChild;
    }

    next.data = {
      ...next.data,
      rtl: isRtl,
      locale: language,
      country: country,
    };

    if (next.firstChild) {
      next.firstChild.data = {
        ...next.firstChild.data,
        rtl: isRtl,
        locale: language,
        country: country,
      };
    }

    if (!route.data.country.length) {
      console.log('No country data found');
    }

    UserTiming.createMark(PERFORMANCE_MARK_END);
    UserTiming.measure(
      PERFORMANCE_MARK_NAME,
      PERFORMANCE_MARK_START,
      PERFORMANCE_MARK_END
    );
    return true;
  } */
}

// version Angular 17 CanActivateFn
/* export const languageGuardFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const injector = inject(Injector);
  const document = injector.get(DOCUMENT);
  let country, language;

  const this.requestURL = '';
  if (this.clientRequest) {
    this.requestURL = `${this.clientRequest.get('host')}${
      this.clientRequest.originalUrl
    }`;
  } else if (this.document) {
    this.requestURL = `${this.document.location.origin || ''}${routerState.url}`;
  }
  // check for locale-country in the route.data

  const country = getCountryCode(
    'US',
    this.requestURL,
    this.refreshSentryLocaleMetadata.bind(this)
  ).country;

  console.log('Country', country);

  if (!route.data.country.length) {
    const fragments = state.url.split('/').filter(Boolean);
    console.log('Fragments', fragments);
    console.log('No country data found', state);

    return router.createUrlTree(['ar-om', ...fragments]);
  }

  return true;
}; */
