export class CarouselBannerSlide {
  public folder: string;
  public bgColor: string;
  public urlLink: string;

  constructor() {
    this.folder = '';
    this.bgColor = '';
    this.urlLink = '';
  }
}

export const parseCarouselBannerImages = (
  data?: any | null
): Array<CarouselBannerSlide> | null => {
  console.log('data', data);
  let out: Array<CarouselBannerSlide> = new Array(0);
  data = JSON.parse(data);

  if (data && data.slides.length > 0) {
    out = new Array(0);
    data.slides.forEach((slide: any) => {
      out.push(parseCarouselBannerSlideFromJson(slide));
    });
  }
  return out;
};

export const parseCarouselBannerSlideFromJson = (data?: any): CarouselBannerSlide => {
  let out = new CarouselBannerSlide();
  if (data) {
    out = new CarouselBannerSlide();
    out.folder = data['folder'] || '';
    out.bgColor = data['bg_color'] || '';
    out.urlLink = data['link_url'] || '';
  }
  return out;
};
